.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.Dropdown {
  width: 250px
}

.ColorSelector {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.small .react-colorful {
  width: 150px;
  height: 120px;
}

.FlagOptions {
  display: flex;
  flex-direction: row;
  align-items: 'center';
  justify-content: center;
}

.RandomColorsButton {
  margin-left: 15px;
}

.RandomStyleButton {
  margin-left: 15px;
}

.DlFlagButton {
  margin-left: 15px;
}

.Centered {
  display: 'flex';
  align-items: 'center';
  justify-content: center;
}

.Flag {
  display: 'flex';
  align-items: 'center';
  justify-content: center;
  margin-top: 0px;
  zoom: 0.5;
  transform: scale(0.5);
  -moz-transform: scale(0.5);
}

h1 {
  margin-top: 5px;
}

p {
  font-size: small;
}
